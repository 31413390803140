

.form-align-box1{
  display: flex;
    flex-direction: column;
}
.form-align-box2{
  display: flex;
    flex-direction: column-reverse;
}

.signupbox {
  padding: 0px 35px 50px 35px;
  border-radius: 15px;
  background: #e4e4e4;
  box-shadow: 2px 2px 10px #443d3d;
  margin-bottom: 10px;
  margin-right: 10px;
}

.filter-select {
  border-radius: 25px;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0;
  background: #f8f8f8;
  height: 40px;
}

/* filter select */
.filter-select__control {
  /* border-radius: 50rem !important;
	border-color: #212529 !important; */
  border-radius: 25px !important;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  background: #f8f8f8;
  height: 50px;
}

.filter-select__value-container {
  padding: 0.375rem 0 0.375rem 1.5rem !important;
}

.filter-select__indicator-separator {
  background-color: transparent !important;
}

.filter-select__input-container {
  padding: 0 !important;
}

.filter-select__placeholder,
.filter-select__single-value {
  margin: 0 !important;
  color: rgb(16 40 75 / 70%) !important;
  font-weight: 400 !important;
}

.filter-select__single-value {
  color: #212529 !important;
}

.filter-select__indicator {
  color: #212529 !important;
}

.filter-select__input-container {
  margin: 0 !important;
}

/* filter select */
/* filter select signup */
.form-signup .filter-select__control {
  border-radius: 50rem !important;
  border: 1px solid rgb(22 39 73 / 40%) !important;
}

.form-signup .filter-select__value-container {
  padding: 0.375rem 1rem 0.375rem 0.75rem !important;
  line-height: 35px;
}

.form-signup .filter-select__indicator-separator {
  background-color: transparent !important;
}

.form-signup .filter-select__input-container {
  padding: 0 !important;
}

.form-signup .filter-select__placeholder,
.filter-select__single-value {
  margin: 0 !important;
  color: #212529 !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
}

.form-signup .filter-select__single-value {
  color: #212529 !important;
}

.form-signup .filter-select__indicator {
  color: #212529 !important;
}

.form-signup .filter-select__input-container {
  margin: 0 !important;
}

/* filter select */

/*form*/
.form-control {
  background-color: #fff;
  color: #10284b;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 35px;
  border-radius: 25px !important;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  background: #f8f8f8;
  height: 50px;
}

.form-test{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #10284b;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 35px;
  border-radius: 25px !important;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  /* background: #f8f8f8; */
  height: 50px;
}

.input-style{
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  color: #10284b;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 35px;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
  border: none !important;
  background: #f8f8f8;
  height: 40px;
}

.input-style:focus{
  box-shadow: none !important;
}

.input-group-text{
  border: none !important;
  background-color: rgb(229, 226, 226) !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  margin-left: -1rem !important;
}

.form-control::placeholder,
.form-controlt::-ms-input-placeholder {
  color: #fefefe;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgb(16 40 75 / 15%);
}

/*form*/

/* form-select  */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: grey !important;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  border-radius: 25px !important;
  margin-bottom: 12px !important;
  border: 2px solid #d0d0d0 !important;
  height: 50px !important;
}

/* form-select  */
.submitbtn {
  margin-top: 10px;
  background: #ff9000;
  border-radius: 30px;
  box-shadow: 2px 2px 5px #000;
  color: #042344;
  text-transform: none;
  font-size: 18px;
}

.otpBox {
  height: 46px;
  width: 46px;
  border: 0.5px solid #707070;
  border-radius: 15px;
  text-align: center;
}

.btn {
  font-size: 1rem !important;
}

.submitOTP-stack {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.consent-input {
  text-align: center;
  color: white;
  padding: 10px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 13px;
}

.consent-input a {
  color: #fff;
}

@media screen and (max-width: 480px) {
  .consent-input {
    padding: 5px;
    margin-top: 5px;
  }

  .submitOTP-stack {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signupbox {
    padding: 0px 15px 15px 15px;
  }

  .otpBox {
    height: 30px;
    width: 30px;
    border: 0.5px solid #707070;
    border-radius: 15px;
    text-align: center;
  }

  .btn {
    font-size: 8px !important;
  }
}