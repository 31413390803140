.main-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.logo {
    padding-bottom: 20px;
}
.container-modal{
    background-color: rgb(255, 255, 255);
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    text-align: center;
}
.title{
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #606060;
    text-align: center;
}
.message {
    font-size: 16px;
    color: #606060;
}
.invite-head{
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #606060;
}

.icon-container {
    background-color: #666666;
    padding: 5px;
}

.icon-link {
    color: white;
}

.privacy{
    font-size: 14px;
    color: #adaeac;
    padding-top: 30px;
}

.btnClose{
    /* background-color: #411b65 !important; */
    color: white !important;
    position: absolute !important;
    bottom: 10px !important;
}

@media only screen and (max-width: 700px){
    .container-modal{
        width: 90%;
        text-align: center;
    }   
    .btnClose{
        right: 0;
    }
    .logo {
        padding-bottom: 20px;
    }
}
