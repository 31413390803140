.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(23px, 5px) scale(0.75) !important;
}

.footer-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.icon-container {
    margin-left: 10px;
}

.icon-link {
    margin-left: 10px;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    padding-left: 23px;
    padding-top: 21px;
}

.form-width {
    width: 210px
}

.country-select {
    width: 250px
}

.from-country .filter-select__control {
    border-radius: 50rem !important;
    border: 0px solid rgb(22 39 73 / 40%) !important;
    margin-bottom: 0px !important;
}

.from-country .filter-select__control:focus {
    border-radius: 50rem !important;
    border: 0px solid rgb(22 39 73 / 40%) !important;
    margin-bottom: 0px !important;
}

.test {
    width: 2rem !important;
    height: 2rem;
    border-radius: 10px;
    border: 1px solid grey;
}

/* .home-background {
    width: 2rem !important;
    height: 2rem;
    border-radius: 10px;
    border: 1px solid grey;
} */

.form-header {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    color: white;
}

.CardContainer-box {
    margin-top: 3.5rem;
}

.back-img {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
}

.ranking {
    padding-bottom: 20px;
}

.hackathon-header {
    font-weight: bold;
}

.ranking-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.rank-text {
    font-weight: bold;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-rows {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.submit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.consent-input {
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 10px;
    padding-right: 25px;
    padding-left: 25px;
    font-size: 13px;
}

.consent-input a {
    color: #0000ae;
    font-weight: bold;
}

.first-img {
    background-color: white;
    border-radius: 0px 0px 40px 40px;
}



@media only screen and (max-width: 700px) {
    .consent-input {
        padding: 5px;
        margin-top: 5px;
    }

    .back-img {
        display: none;
    }

    .test {
        width: 1.5rem !important;
        height: 1.5rem;
        border-radius: 8px;
        border: none;
    }

    .home-background {
        width: 1.5rem !important;
        height: 55px;
        border-radius: 8px;
        border: none;
    }

    .first-img {
        height: 90px;
        width: "auto"
    }

    .form-rows {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .submit-button {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(17px, 22px) scale(0.7) !important;
    }

    .footer-end {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

}